<template>

  <div class="bg-container page">
    <img class="bg-img" src="img/login/login-bg.png">
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#788ebb" @click="back()"/>
          </template>
		  
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:''">
            </div>
          </div>
          <!--div class="title">{{$t("auth.register")}}</div-->
          <div class="loginForm">
            <van-field v-model="username"  clearable input-align="left" class="input"  :placeholder="$t('auth.username_place')" >
				<van-icon
				  name="img/login/login_username.png" 
				   slot="left-icon"
				/>
			 </van-field>	
				
            
			
			<van-field
                v-model="password"
                :type="passwordType"
                input-align="left"
                class="input"
                :placeholder="$t('auth.pwd_place')">
              <template slot="left-icon">
              	  <van-icon
              	    name="img/login/login_userpassword.png"
              	  />
              	</template>
              
                
              </van-field>
			
			<van-field
			    v-model="paypassword"
			    :type="paypasswordType"
			    input-align="left"
			    class="input"
			    :placeholder="$t('auth.paypwd_place')">
			  <template slot="left-icon">
			  	  <van-icon
			  	    name="img/login/login_userpassword.png"
			  	  />
			  	</template>
			  
			    
			  </van-field>
			
            <!--van-field v-model="code"  clearable input-align="left" class="input"  :placeholder="$t('auth.invite_code_place')" >
				
				<template slot="left-icon">
					  <van-icon
					    name="img/login/login_userpassword.png"
					  />
					</template>
				
				  
				</van-field-->
				
				
            <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">{{$t("auth.agreement_place")}}</span>
            </div>
			
            <van-button class="login-btn"  
			 type="primary" 
			 size="normal"
			  @click="doRegister()">{{$t("auth.register")}}
			  </van-button>
          
				<van-button class="login-btn register-btn"
				 type="primary" 
				 size="normal"
				  @click="toLogin()">{{$t("auth.login")}}
				  </van-button>
		  
		  </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      lang: 'en_us',
      password: this.inputValue,
      passwordType: 'password',
	  paypassword: this.inputValue,
	  paypasswordType: 'password',
    };
  },
  mounted(){
    this.lang = localStorage.getItem("lang") || 'es_spa';
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
	toLogin() {
	  this.$router.push("Login");
	},
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast.fail(this.$t("auth.username_place"));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast.fail(this.$t("auth.pwd_place"));
        return false;
      }
	  
	  if(this.paypassword === "" || this.paypassword === null || this.paypassword === undefined){
	    this.$toast.fail(this.$t("auth.pwd_place"));
	    return false;
	  }
	  
	  
	  
     // if(this.code === "" || this.code === null || this.code === undefined){
       // this.$toast.fail(this.$t("auth.invite_code_place"));
       // return false;
     // }
      if(!this.checked){
        this.$toast.fail(this.$t("auth.agreement"));
        return false;
      }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
		  paypassword:this.paypassword,
          code:'123456',
          lang: this.lang
        },
        url: 'member_register'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register{
  height: 100%;
}
.bg-container .bg-wrapper .register .nav-bar{
  background: 0 0
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 5vw auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.register .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.register .wrapper .loginForm{
  padding: 0 45px;
}
.register .wrapper .loginForm .input{
  padding: 1vw 4vw;
  margin-top: 3.333vw;
  border-radius: 50px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  color: #4e4e4e;
}

::v-deep .van-field__control{
	margin-left: 1vw;
	    padding: 0 2vw;
}
::v-deep input:-internal-autofill-selected{
	
	background-color: transparent !important;
	    border-radius: 6.667vw;
		
}





::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .login-btn{
  margin-top: 20px;
  width: 100%;
  height: 80px;
  border-radius: 50px;
  color: #fff;
   background-image: url('../images/user/login_otp_btn.png');
       background-size: cover;
       background-color: transparent !important;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}


.register-btn{
	opacity: 0.7;
}

.register .wrapper .loginForm .agreement{
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text{
  margin-left: 10px;
  font-size: 25px;
  color: #718fbd;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 25px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 35px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: #718fbd;
  background-color: #718fbd;
}
</style>
